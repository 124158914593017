@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');


html {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  padding: 20px 50px;
}

body{
  box-sizing: border-box;
padding:0px;
margin:0px;
}


html,
body,
#root,
.app {
  background-color: #050A30;
  color: #fff;
  box-sizing: border-box;
  width: 100%;
}

@media screen and (max-width: 900px) {
  html {
    padding: 10px 30px;
  }
}

@media screen and (max-width: 600px) {
  html {
    padding: 10px 20px;
  }
}